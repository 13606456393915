import { useTranslation } from 'react-i18next'
import { TenderStatistics } from '../../../entities/TenderStatistics'
import { classNames } from '../../../shared/lib/classNames/classNames'
import { MainGridComponent } from '../../../shared/ui/MainGridComponent/MainGridComponent'
import { MainPageAlert } from '../../../shared/ui/MainPageAlert/MainPageAlert'
import { MainPageFilter } from '../../../widgets/MainPageFilter/MainPageFilter'

import cls from './MainPage.module.scss'
import { TendersNew } from '../../../entities/Tenders'
import Features from '../../../entities/Features/Features'
import { ContactsPage } from '../../ContactsPage'

export const MainPage = () => {
    const { t } = useTranslation()
    return (
        <div className={classNames(cls.MainPage, {}, [])}>
            <MainGridComponent>
                <>
                    <MainPageAlert
                        title={t(
                            'ВСЕ ТЕНДЕРЫ СТРАНЫ - ВАША ПОБЕДА НАЧИНАЕТСЯ ЗДЕСЬ!'
                        )}
                        text={t('Найди любой тендер!')}
                    />
                    <MainPageFilter />
                </>
            </MainGridComponent>
            <TenderStatistics />
            <MainGridComponent>
                <TendersNew />
                <Features />
                <ContactsPage />
            </MainGridComponent>
        </div>
    )
}
