import React from 'react'
import { ReactComponent as Sync } from '../../shared/assets/icons/a-sync.svg'
import { ReactComponent as Layers } from '../../shared/assets/icons/ic_layers_48px.svg'
import { ReactComponent as Cart } from '../../shared/assets/icons/ic_shopping_cart_48px.svg'
import { ReactComponent as Circle } from '../../shared/assets/icons/preferences-circle.svg'
import { HStack } from '../../shared/ui/Stack'
import styles from './Features.module.scss'

const Features: React.FC = () => {
    return (
        <section className={styles.featuresSection}>
            <h2 className={styles.title}>Преимущества работы с нами</h2>
            <p className={styles.subtitle}>
                With lots of unique blocks, you can easily build a page without
                coding. Build your
                <br />
                next consultancy website within few minutes.
            </p>

            <div className={styles.featuresGrid}>
                <div className={styles.featureItem}>
                    <HStack gap="8" align="start">
                        <div className={styles.icon}>
                            <Circle />
                        </div>
                        <div className={styles.block}>
                            <h3>
                                Все тендеры Кыргызстана
                                <br />в одном месте
                            </h3>
                            <p>
                                Мы предоставляем доступ ко всем тендерам в
                                стране, что позволяет вам легко
                                <br />
                                находить нужные предложения.
                            </p>
                        </div>
                    </HStack>
                </div>

                <div className={styles.featureItem}>
                    <HStack gap="8" align="start">
                        <div className={styles.icon}>
                            <Cart />
                        </div>
                        <div className={styles.block}>
                            <h3>Точные результаты поиска</h3>
                            <p>
                                Найдите нужный тендер быстро
                                <br />и легко
                            </p>
                        </div>
                    </HStack>
                </div>

                <div className={styles.featureItem}>
                    <HStack gap="8" align="start">
                        <div className={styles.icon}>
                            <Layers />
                        </div>
                        <div className={styles.block}>
                            <h3>
                                Комплексный доступ
                                <br />
                                ко всем тендерам
                            </h3>
                            <p>Экономьте время и ресурсы!</p>
                        </div>
                    </HStack>
                </div>

                <div className={styles.featureItem}>
                    <HStack gap="8" align="start">
                        <div className={styles.icon}>
                            <Sync />
                        </div>
                        <div className={styles.block}>
                            <h3>
                                Интуитивно понятный
                                <br />
                                поисковик
                            </h3>
                            <p>
                                Простой и удобный интерфейс для
                                <br />
                                быстрого поиска тендеров!
                            </p>
                        </div>
                    </HStack>
                </div>
            </div>
        </section>
    )
}

export default Features
